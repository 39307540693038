import React from 'react'
import { styled } from '@mui/material'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff'

//components
import StyledPageContainer from '../../components/StyledPageContainer/StyledPageContainer'
import StyledTable from '../../components/ReactTable/StyledTable'
import StyledTableContainer from '../../components/StyledTableContainer/StyledTableContainer'
import StyledEmptyHeader from '../../components/StyledEmptyHeader/StyledEmptyHeader'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import ToolUploadModal from './AddToolModal'
import ConfirmationModal from '../../components/ConfirmationModal/ConfirmationModal'

//assets
import { DeleteIcon, EditIcon } from '../../icons/Icons'

//utils
import { showNotification } from '../../utils/toast.utils'
import { MACHINES } from '../../utils/constant.utils'

//services
import toolService from '../../service/toolLife.service'

//types
import { OptionItem } from '../../types/common'
import { TypeAddToolData, ToolsData } from '../../types/tools'
import { getDisplayName } from '../../utils/common.utils'

const FilterContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'flex-between',
  alignItems: 'center',
  columnGap: '16px'
}))

const machineOptions = MACHINES.map((machine) => ({
  value: machine.value,
  label: machine.label
}))

const ActionBox = ({ children }: { children: React.ReactNode }) => {
  return (
    <div style={{ display: 'flex', gap: '8px', justifyContent: 'center' }}>
      {children}
    </div>
  )
}

const ToolManagement = () => {
  const defaultMachine = {
    value: 'all',
    label: 'All machines'
  }
  const defaultToolIdNo = {
    value: 'all',
    label: 'All tool id'
  }
  const defaultToolType = {
    value: 'all',
    label: 'All tool type'
  }
  const defaultToolSize = {
    value: 'all',
    label: 'All tool size'
  }

  const [tools, setTools] = React.useState<Record<number, ToolsData> | null>(
    null
  )
  const [toolIdOptions, setToolIdOption] = React.useState<OptionItem[]>([])
  const [toolTypeOptions, setToolTypeOption] = React.useState<OptionItem[]>([])
  const [toolSizeOptions, setToolSizeOption] = React.useState<OptionItem[]>([])

  const [toolId, setToolId] = React.useState<OptionItem>(defaultToolIdNo)
  const [toolType, setToolType] = React.useState<OptionItem>(defaultToolType)
  const [toolSize, setToolSize] = React.useState<OptionItem>(defaultToolSize)
  const [machine, setMachine] = React.useState<OptionItem>(defaultMachine)

  const [isLoading, setIsLoading] = React.useState(false)
  const [isEditMode, setIsEditMode] = React.useState(false)
  const [openAddToolModal, setOpenAddToolModal] = React.useState(false)
  const [selectedTool, setSelectedTool] = React.useState<ToolsData | null>(null)
  const [openDeleteModal, setOpenDeleteModal] = React.useState(false)

  const fetchTools = async () => {
    const data = {}

    setIsLoading(true)
    const toolsResponse = await toolService.getTools(data)

    if (toolsResponse.success) {
      const toolsData: Record<number, ToolsData> = {}
      const toolIdNoOptionsObj: Record<string, OptionItem> = {}
      const toolTypeOptionsObj: Record<string, OptionItem> = {}
      const toolSizeOptionsObj: Record<string, OptionItem> = {}

      toolsResponse.data.forEach((tool, i) => {
        if (!toolIdNoOptionsObj[tool.toolId]) {
          toolIdNoOptionsObj[tool.toolId] = {
            label: tool.toolId,
            value: tool.toolId
          }
        }

        if (!toolTypeOptionsObj[tool.toolType]) {
          toolTypeOptionsObj[tool.toolType] = {
            label: tool.toolType,
            value: tool.toolType
          }
        }
        if (!toolSizeOptionsObj[tool.toolSize]) {
          toolSizeOptionsObj[tool.toolSize] = {
            label: tool.toolSize,
            value: tool.toolSize
          }
        }
        if (!toolsData[tool.id]) {
          toolsData[tool.id] = {
            index: i + 1,
            ...tool
          }
        }
      })

      setToolIdOption(Object.values(toolIdNoOptionsObj))
      setToolTypeOption(Object.values(toolTypeOptionsObj))
      setToolSizeOption(Object.values(toolSizeOptionsObj))
      setTools(toolsData)
    } else {
      //TODO: set error or show error
      setTools(null)
    }

    setIsLoading(false)
  }

  React.useEffect(() => {
    fetchTools()
  }, [])

  const addTool = async (toolData: TypeAddToolData) => {
    if (!toolData.toolId) {
      showNotification('Tool toolId is required', 'error')
      return
    }

    if (!toolData.toolType) {
      showNotification('Tool toolType is required', 'error')
      return
    }

    if (!toolData.machine && toolData.machine === '') {
      showNotification('Machine is required', 'error')
      return
    }
    if (!toolData.toolSize && toolData.toolSize === '') {
      showNotification('Tool size is required', 'error')
      return
    }

    setIsLoading(true)
    const toolsResponse = await toolService.addTool(toolData)
    if (toolsResponse.success) {
      showNotification(`Tool added successfully`, 'success')
    } else {
      showNotification(toolsResponse.message, 'error')
    }
    setOpenAddToolModal(false)
    fetchTools()
    setIsLoading(false)
  }

  const editTool = async (id: number, toolData: TypeAddToolData) => {
    if (!toolData.toolId) {
      showNotification('Tool toolId is required', 'error')
      return
    }

    if (!toolData.toolType) {
      showNotification('Tool toolType is required', 'error')
      return
    }

    if (!toolData.machine && toolData.machine === '') {
      showNotification('Machine is required', 'error')
      return
    }
    if (!toolData.toolSize && toolData.toolSize === '') {
      showNotification('toolSize is required', 'error')
      return
    }

    setIsLoading(true)
    const toolsResponse = await toolService.editTool(id, toolData)
    if (toolsResponse.success) {
      showNotification(`Tool updated successfully`, 'success')
    } else {
      showNotification(toolsResponse.message, 'error')
    }

    fetchTools()
    setIsEditMode(false)
    setOpenAddToolModal(false)
    setIsLoading(false)
  }

  const deleteTool = async (toolId: number) => {
    if (!toolId) {
      showNotification('Tool id is required', 'error')
      return
    }

    try {
      setIsLoading(true)
      const toolResponse = await toolService.deleteTool(toolId)
      if (toolResponse.data.success) {
        showNotification(`Tool deleted successfully`, 'success')
      } else {
        showNotification(toolResponse.data.message, 'error')
      }
      fetchTools()
    } catch (err) {
      console.log(err)
    } finally {
      setIsLoading(false)
    }
  }

  //1. Create search object
  const searchObj = {
    ...(toolId &&
      toolId.value !== 'all' && {
        toolId: toolId.value
      }),
    ...(toolType &&
      toolType.value !== 'all' && {
        toolType: toolType.value
      }),
    ...(toolSize &&
      toolSize.value !== 'all' && {
        toolSize: toolSize.value
      }),
    ...(machine &&
      machine.value !== 'all' && {
        machine: machine.value
      })
  }

  //2. Extract the keys into an array
  const keysToChecked = Object.keys(searchObj) as (keyof typeof searchObj)[]

  //3. filter
  const filteredRows = React.useMemo(() => {
    let filteredRows = tools ? Object.values(tools) : []
    if (tools && keysToChecked.length > 0) {
      filteredRows = Object.values(tools).filter((toolRow) => {
        return keysToChecked.every((key) => searchObj[key] === toolRow[key])
      })
    }

    return filteredRows
  }, [tools, toolType, toolId, toolSize])

  const toolColumns = [
    {
      Header: 'S.No',
      accessor: 'index'
    },
    {
      Header: 'Id',
      accessor: 'id'
    },
    {
      Header: 'Tool ID',
      accessor: 'toolId'
    },
    {
      Header: 'Tool type',
      accessor: 'toolType'
    },
    {
      Header: 'Tool size',
      accessor: 'toolSize'
    },
    {
      Header: 'Machine',
      accessor: 'machine',
      Cell: ({ cell: { row } }: { cell: { row: { values: ToolsData } } }) => {
        return getDisplayName(row.values.machine, MACHINES)
      }
    },
    {
      Header: 'Tool life',
      accessor: 'toolLife'
    },
    // {
    //   Header: 'Start date',
    //   accessor: 'startDate'
    // },
    // {
    //   Header: 'Start time',
    //   accessor: 'startTime'
    // },
    {
      Header: 'Action',
      Cell: ({ cell: { row } }: { cell: { row: { values: ToolsData } } }) => {
        return (
          <ActionBox>
            <EditIcon
              onClickHandler={() => {
                setIsEditMode(true)
                setSelectedTool({ ...row.values })
                setOpenAddToolModal(true)
              }}
            />
            <DeleteIcon
              onClickHandler={() => {
                setOpenDeleteModal(true)
                setSelectedTool({ ...row.values })
              }}
            />
          </ActionBox>
        )
      }
    }
  ]

  return (
    <>
      <Stack
        sx={{
          position: 'sticky',
          top: 0,
          width: '100%',
          flexDirection: 'column',
          gap: '12px',
          borderBottom: '1px solid #eeeeee',
          backgroundColor: '#fff',
          padding: '12px 16px',
          zIndex: 100
        }}
      >
        <FilterContainer>
          <Button
            variant="contained"
            sx={{
              color: '#fff',
              textTransform: 'none'
            }}
            onClick={() => {
              setIsEditMode(false)
              setSelectedTool(null)
              setOpenAddToolModal(true)
            }}
          >
            Add new tool
          </Button>
        </FilterContainer>
        <Stack
          spacing={2}
          direction="row"
          sx={{ width: '100%', alignItems: 'center' }}
        >
          <StyledSelectField
            label=""
            placeHolderText="Select tool id"
            selectedValue={toolId}
            selectOptions={[defaultToolIdNo, ...toolIdOptions]}
            onChangeHandler={(value: OptionItem) => setToolId(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No tool id found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select tool type"
            selectedValue={toolType}
            selectOptions={[defaultToolType, ...toolTypeOptions]}
            onChangeHandler={(value: OptionItem) => setToolType(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No tool type found"
          />

          <StyledSelectField
            label=""
            placeHolderText="Select tool size"
            selectedValue={toolSize}
            selectOptions={[defaultToolSize, ...toolSizeOptions]}
            onChangeHandler={(value: OptionItem) => setToolSize(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No tool Size found"
          />
          <StyledSelectField
            label=""
            placeHolderText="Select machine"
            selectedValue={machine}
            selectOptions={[defaultMachine, ...machineOptions]}
            onChangeHandler={(value: OptionItem) => setMachine(value)}
            isMulti={false}
            isSearchable={true}
            isDisabled={false}
            noOptionsMessage="No machine found"
          />
        </Stack>
      </Stack>
      <Box sx={{ padding: '16px' }}>
        <StyledTableContainer>
          {isLoading && <StyledEmptyHeader>Loading...</StyledEmptyHeader>}
          {!isLoading && filteredRows.length === 0 && (
            <StyledEmptyHeader>There are no tools</StyledEmptyHeader>
          )}
          {!isLoading && filteredRows.length > 0 && (
            <StyledTable
              pagination={false}
              columns={toolColumns}
              data={filteredRows}
              hiddenColumns={['id', 'index']}
            />
          )}
        </StyledTableContainer>
      </Box>
      {openAddToolModal && (
        <ToolUploadModal
          closeHandler={() => setOpenAddToolModal(false)}
          isModalOpen={openAddToolModal}
          addTool={addTool}
          editTool={editTool}
          isEditMode={isEditMode}
          selectedTool={selectedTool}
        />
      )}
      {selectedTool && (
        <ConfirmationModal
          isModalOpen={openDeleteModal}
          description={`Do you want to the following variant ${selectedTool.toolId}_${selectedTool.toolType}_${selectedTool.toolSize}_${selectedTool.machine}`}
          buttonTitle="Delete"
          clickHandler={() => deleteTool(selectedTool.id)}
          closeHandler={() => setOpenDeleteModal(false)}
        />
      )}
    </>
  )
}

export default ToolManagement
