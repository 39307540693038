import React, { useState, useEffect } from 'react'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import StyledModal from '../../components/StyledModal/Modal'
import StyledSelectField from '../../components/StyledSelectField/StyledSelectField'
import StyledTextFieldWithLabel from '../../components/StyledTextFieldWithLabel/StyledTextFieldWithLabel'
import { MACHINES } from '../../utils/constant.utils'
import { ToolsData, TypeAddToolData } from '../../types/tools'
import { OptionItem } from '../../types/common'

interface Props {
  isModalOpen: boolean
  closeHandler: () => void
  selectedTool: ToolsData | null
  addTool?: (toolData: TypeAddToolData) => void
  editTool?: (id: number, newData: TypeAddToolData) => void
  isEditMode?: boolean
  isLoading?: boolean
}

const ToolUploadModal = (props: Props) => {
  let defaultToolIdNo = ''
  // let defaultStartDate = ''
  // let defaultStartTime = ''
  let defaultToolType: OptionItem = {
    value: 0,
    label: 'Select tool type'
  }
  let defaultToolSize = ''
  let defaultToolLife: string | number = ''
  let defaultMachine: OptionItem = {
    value: 0,
    label: 'Select machine'
  }

  if (props.isEditMode && props.selectedTool) {
    defaultToolIdNo = props.selectedTool.toolId

    defaultToolType.value = props.selectedTool.toolType
    defaultToolType.label = props.selectedTool.toolType

    defaultMachine.value = props.selectedTool.machine
    defaultMachine.label = props.selectedTool.machine.toUpperCase()

    defaultToolSize = props.selectedTool.toolSize
    defaultToolLife = props.selectedTool.toolLife
    // defaultStartDate = props.selectedTool.startDate
    // defaultStartTime = props.selectedTool.startTime
  }

  const [toolTypeOptions, setToolTypeOptions] = useState([])
  const [toolIdNo, setToolIdNo] = useState(defaultToolIdNo)
  const [toolType, setToolType] = useState(defaultToolType)
  const [toolSize, setToolSize] = useState(defaultToolSize)
  const [machine, setMachine] = useState(defaultMachine)
  const [toolLife, setToolLife] = useState(defaultToolLife)
  // const [startDate, setStartDate] = useState(defaultStartDate)
  // const [startTime, setStartTime] = useState(defaultStartTime)

  useEffect(() => {
    fetch(`${process.env.REACT_APP_ADMIN_BASE_URL}/v1/tool-type`)
      .then((response) => response.json())
      .then((data) => {
        const toolTypeOptions = data.data.map((item: any) => ({
          value: item.type,
          label: item.type
        }))
        setToolTypeOptions(toolTypeOptions)
      })
      .catch((error) => {
        console.log(error)
      })
  }, [])

  return (
    <StyledModal
      width={500}
      loading={props.isLoading}
      isOpen={props.isModalOpen}
      title={props.isEditMode ? 'Edit tool info' : 'Add tool'}
      closeHandler={() => props.closeHandler()}
    >
      <Stack
        spacing={2}
        direction="column"
        sx={{ marginBottom: '16px', width: '100%' }}
      >
        <StyledSelectField
          label="Tool type"
          placeholderText="Enter tool type"
          selectedValue={toolType}
          selectOptions={toolTypeOptions}
          onChangeHandler={(value: OptionItem) => setToolType(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No tool type found"
        />
        <StyledTextFieldWithLabel
          label="Tool size"
          placeholderText="Enter tool size"
          textValue={toolSize}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToolSize(e.target.value)
          }}
          isDisabled={false}
          type="text"
          isMargin={true}
          noOptionsMessage="No tool size entered"
        />
        <StyledTextFieldWithLabel
          label="Tool id"
          placeholderText="Enter tool id"
          textValue={toolIdNo}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            setToolIdNo(e.target.value)
          }}
          isDisabled={false}
          type="text"
          isMargin={true}
          noOptionsMessage="No tool id no entered"
        />
        <StyledTextFieldWithLabel
          label="Tool life"
          placeholderText="Enter tool life"
          textValue={toolLife}
          onChangeHandler={(e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.target.value
            if (parseInt(inputValue) < 0) {
              // If it's below zero, set the value to an empty string
              setToolLife('')
            } else {
              // Otherwise, update the state with the new value
              setToolLife(parseInt(inputValue))
            }
          }}
          isDisabled={false}
          type="number"
          isMargin={true}
        />
        <StyledSelectField
          label="Machine"
          placeHolderText="Select machine"
          selectedValue={machine}
          selectOptions={MACHINES}
          onChangeHandler={(value: OptionItem) => setMachine(value)}
          isMulti={false}
          isSearchable={true}
          isDisabled={false}
          noOptionsMessage="No machine found"
        />
        {/* <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            flex: 1
          }}
        >
          <Typography sx={{ marginBottom: '4px', fontSize: '16px' }}>
            Date
          </Typography>
          <input
            value={startDate}
            type="date"
            style={{
              width: '100%',
              height: '46px',
              padding: '0 12px',
              fontSize: '16px',
              fontWeight: 500,
              border: '1px solid #a8a8b5',
              borderRadius: '8px',
              boxShadow: '0 2px 7px 0 rgb(18 18 19 / 6%)'
            }}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </Box>

        <Box */}
        {/* // sx={{ display: 'flex', flexDirection: 'column', gap: '8px', flex: 1 }}
        > */}
        {/* <Typography sx={{ marginBottom: '4px', fontSize: '16px' }}>
            Time
          </Typography> */}
        {/* <input
            value={startTime}
            type="time"
            style={{
              width: '100%',
              fontSize: '16px',
              gap: '8px',
              height: '46px',
              padding: '0 12px',
              fontWeight: 500,
              overflow: 'auto',
              border: '1px solid #a8a8b5',
              borderRadius: '8px',
              boxShadow: '0 2px 7px 0 rgb(18 18 19 / 6%)'
            }}
            onChange={(e) => setStartTime(e.target.value)}
          /> */}
        {/* </Box> */}
      </Stack>

      <Stack
        spacing={2}
        direction="row"
        sx={{
          marginTop: '16px',
          display: 'flex',
          justifyContent: 'flex-end'
        }}
      >
        <Button variant="outlined" onClick={props.closeHandler}>
          Cancel
        </Button>
        <Button
          variant="contained"
          sx={{ color: '#fff' }}
          onClick={() =>
            props.isEditMode && props.selectedTool
              ? props.editTool &&
                props.editTool(props.selectedTool.id, {
                  toolType: toolType.value,
                  toolId: toolIdNo,
                  toolSize: toolSize,
                  toolLife: parseInt(String(toolLife)),
                  machine: machine.value
                  // startDate: startDate,
                  // startTime: startTime
                })
              : props.addTool &&
                props.addTool({
                  toolType: toolType.value,
                  toolId: toolIdNo,
                  toolSize: toolSize,
                  toolLife: parseInt(String(toolLife)),
                  machine: machine.value
                  // startDate: startDate,
                  // startTime: startTime
                })
          }
        >
          {props.isEditMode ? 'Update' : 'Add tool'}
        </Button>
      </Stack>
    </StyledModal>
  )
}

export default ToolUploadModal
