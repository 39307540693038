import axios from 'axios'

import { IPart, PartFilter, TypeAddPartData } from '../types/parts'
import { ApiErrorResponse, ApiSuccessResponse } from '../types/common'

const axiosObj = axios.create({
  baseURL: `${process.env.REACT_APP_ADMIN_BASE_URL}/v1`,
  headers: {
    'Content-Type': 'application/json'
  },
  responseType: 'json'
})

export const handleApiError = (error: unknown): ApiErrorResponse => {
  if (axios.isAxiosError(error)) {
    // Handle specific HTTP status codes if needed

    // NOTE: This syntax has some parsing problem
    // if (error.response?.status === 404) {
    //   console.log('Not Found Error:', error.message)
    // }

    if (error.response && error.response.status === 400) {
      console.log('Bad request:', error.message)
    }

    if (error.response && error.response.status === 401) {
      console.log('Auth error:', error.message)
    }

    if (error.response && error.response.status === 404) {
      console.log('Not found error:', error.message)
    }

    return {
      success: false,
      message:
        error.response && error.response.data
          ? error.response.data.message
          : 'Internal server error'
    }
  } else {
    // Handle other errors (e.g., network errors)
    return { success: false, message: 'Internal server error' }
  }
}

const partService = {
  getParts: async (
    filter: PartFilter
  ): Promise<ApiSuccessResponse<IPart[]> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.get('/parts', {
        params: {
          ...filter
        }
      })

      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  addPart: async (
    data: TypeAddPartData
  ): Promise<ApiSuccessResponse<IPart> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.post('/parts', data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  editPart: async (
    id: number,
    data: Partial<IPart>
  ): Promise<ApiSuccessResponse<IPart> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(`/parts/${id}`, data)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  deletePart: async (id: number) => {
    try {
      const response = await axiosObj.delete(`/parts/${id}`)
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  updatePartsInHmi: async () => {
    try {
      const response = await axiosObj.put('/hmi/parts')
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  },
  updateProductionList: async (
    partIds: number[],
    isActive: boolean
  ): Promise<ApiSuccessResponse<{}> | ApiErrorResponse> => {
    try {
      const response = await axiosObj.patch(`/parts/update/active-list`, {
        partIds,
        isActive
      })
      return response.data
    } catch (error) {
      return handleApiError(error)
    }
  }
}

export default partService
